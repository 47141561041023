import { Translations } from './utils/translations';
import {
  DAILY_TIMETABLE_WIDGET_CONTROLLER_ID,
  BOOK_BUTTON_WIDGET_CONTROLLER_ID,
  STAFF_LIST_WIDGET_CONTROLLER_ID,
  PageId,
  experiments,
  PRICING_PLANS_DEF_ID,
  WidgetsId,
  HandleActionPayload,
} from './constants';
import {
  addBookingsPagesAsPanel,
  createBookCheckoutState,
  createBookingCalendarState,
  createBookingFormState,
  createServicePageState,
  handleOpenBookingsPagesPanel,
  removeBookCheckoutPageOnEcom,
} from './utils/pages-panel-actions';
import { shouldProposeMigration } from './utils/migrate-actions';
import { getCurrentVersion } from './utils/ci-actions';
import {
  migrator,
  proposeListMigration,
  openEcomHistoryBlockModal,
} from './utils/migration-modals';
import {
  getStateBoxByBookingsAppBuilderWidget,
  getAllBookingsPages,
  getPageData,
  isBookingsCheckoutInstalled,
  onRemoveApp,
  removePage,
  getBookingsAppBuilderWidgetByChildComponentRef,
  isServicePageInstalled,
  isBookingCalendarInstalled,
  isBookingFormInstalled,
  isBookingInstalled,
  ecomRolloutButNotSite,
} from './utils/editor-sdk-actions';
import {
  BookingsAppBuilderStaticsUrls,
  DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID,
  STAFF_LIST_WIDGET_DEV_CENTER_ID,
} from '@wix/bookings-app-builder-controllers/dist/src/platform/platform.const';
import { DailyTimetableEditorComponentModel } from './editor-components/daily-timetable';
import { BookButtonEditorComponentModel } from './editor-components/book-button';
import { StaffListEditorComponentModel } from './editor-components/staff-list';
import { withEcomPlatform } from '@wix/ecom-platform-sdk';
import { isExperimentEnabled } from './utils/experiments';
import {
  createAServiceEvent,
  generateActions,
  manageBookingsEvent,
  manageStaffEvent,
  manageStateEvent,
  openBookButtonSettingsEvent,
  openBookingsAddPanel,
  openBookingsPagesPanel,
  openDailyTimeTableSettingsEvent,
  openDailyTimeTableSettingsTextTabEvent,
  openStaffListSettingsEvent,
  openStaffListTextSettingsEvent,
} from './utils/editor-actions';
import { EditorSdkAdapter } from '@wix/bookings-adapter-editor-sdk';
import {
  EditorSDK,
  EditorReadyOptions,
  InstallationOriginType,
} from '@wix/platform-editor-sdk';
import {
  MA_APP_IDS,
  maybeInstallMembersArea,
  withMembersArea,
} from '@wix/members-area-integration-kit';
import createAppDescriptor from './manifest/app-descriptor/app-descriptor';
import {
  openBookingsDashboardAddNewService,
  openManageBookings,
} from './utils/backoffice-actions';
import { handleOpenBookingsAddPanel } from './utils/app-descriptor-actions';
import {
  addHistoryEventsHandlers,
  onHistoryEvent,
} from './utils/history-events';
import { EditorScriptApi } from './api/api';
import {
  BOOKINGS_APP_DEF_ID,
  ECOM_APP_DEF_ID,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { addBookingCheckoutPage } from './utils/pages-actions';
import {
  markAsEcom,
  skipEcomInstallationAction,
  requiredEcomComponentsAction,
} from './utils/ecom-migration/ecom-actions';
import { migrateToOOI } from './utils/ooi-migration/ooi-migration';

const checkIfUserIsPremium = (bookingsData) => {
  return bookingsData?.vendorProductId ?? false;
};

let isEcomReady = false;
let editorReadyDone = false;
let skipEcomInstallation = async () => false;
let requiredEcomComponents = async () => [];

export const createEditorScript = (isBookingsOnEcom = false) => {
  let instance,
    appToken,
    sdk,
    locale,
    isAdi,
    isProposeMigrationNeeded,
    allSitePages,
    isBookingCalendarPageInstalled,
    editorScriptApi: EditorScriptApi,
    isFirstInstall: boolean;
  const deleteBookingsEvent = 'deleteBookings';
  const deletePageEvent = 'deletePage';
  const pageChangedEvent = 'focusedPageChanged';
  const widgetGfppClicked = 'widgetGfppClicked';
  const componentGfppClicked = 'componentGfppClicked';
  const componentStyleChanged = 'componentStyleChanged';
  const gfppOriginDoubleClick = 'double_click';
  const appActionClicked = 'appActionClicked';

  const editorTranslation = new Translations();
  let bookingsEditorComponentModels;
  let actions;
  let appDescriptor;
  let ooiControllersStageData;
  let isOOITemplateMigrationEnabled;

  return {
    editorReady: async (
      _editorSDK: EditorSDK,
      _appToken: string,
      options: EditorReadyOptions,
    ) => {
      await addHistoryEventsHandlers(_editorSDK);

      isFirstInstall = options?.firstInstall;
      isBookingCalendarPageInstalled = await isBookingCalendarInstalled(
        _editorSDK,
      );
      const editorSdkAdapter = new EditorSdkAdapter(_editorSDK, _appToken);
      editorScriptApi = new EditorScriptApi(
        options.essentials.httpClient as any,
      );
      requiredEcomComponents = requiredEcomComponentsAction({
        isFirstInstall,
        _editorSDK,
        editorScriptApi,
        appToken,
        locale
      });
      skipEcomInstallation = skipEcomInstallationAction({
        isFirstInstall,
        _editorSDK,
        editorScriptApi,
        appToken,
        locale,
      });
      locale = await editorSdkAdapter.getEditorLanguage();
      const currentVersion = await getCurrentVersion(editorSdkAdapter);
      const bookingsData = await editorSdkAdapter.getBookingsData();
      instance = bookingsData.instance;
      await editorTranslation.init(locale, currentVersion);
      actions = generateActions(editorTranslation);
      isOOITemplateMigrationEnabled = await isExperimentEnabled(
        experiments.OOI_TEMPLATE_MIGRATION_ENABLED,
      );
      const isPremium = checkIfUserIsPremium(bookingsData);
      appDescriptor = createAppDescriptor(
        actions,
        isPremium,
        editorTranslation,
      );
      const isSkipMemberInstallationEnabled = await isExperimentEnabled(
        experiments.SKIP_MEMBER_INSTALLATION,
      );
      const isPluginInstallationOnServicePageEnabled =
        await isExperimentEnabled(
          experiments.PLUGIN_INSTALLATION_ON_SERVICE_PAGE_ENABLED,
        );
      ooiControllersStageData = {
        [WidgetsId.CALENDAR_PAGE]: {
          default: {
            layoutLimits: {
              desktop: {
                minWidth: 640,
              },
              mobile: {
                minWidth: 280,
              },
            },
          },
        },
        [WidgetsId.BOOKINGS_LIST_PAGE]: {
          default: {
            layoutLimits: {
              desktop: {
                minWidth: 600,
              },
              mobile: {
                minWidth: 260,
              },
            },
          },
        },
        [WidgetsId.BOOKINGS_LIST_WIDGET]: {
          default: {
            layoutLimits: {
              desktop: {
                minWidth: 600,
              },
              mobile: {
                minWidth: 260,
              },
            },
          },
        },
        ...(isPluginInstallationOnServicePageEnabled
          ? {
              [WidgetsId.SERVICE_PAGE]: {
                default: {
                  slots: {
                    slot1: {
                      displayName: 'slot1',
                      interfaces: [], // TODO serviceId
                    },
                  },
                },
              },
            }
          : {}),
      };
      bookingsEditorComponentModels = {
        [DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID]:
          new DailyTimetableEditorComponentModel(
            editorSdkAdapter,
            editorTranslation,
            actions.manageBookingsAction,
            actions.manageStateAction,
            actions.openDailyTimeTableSettings,
            actions.openDailyTimeTableSettingsTextTab,
          ),
        [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: new BookButtonEditorComponentModel(
          editorSdkAdapter,
          editorTranslation,
          actions.manageBookingsAction,
          actions.bookButtonSettings,
        ),
        [STAFF_LIST_WIDGET_DEV_CENTER_ID]: new StaffListEditorComponentModel(
          editorSdkAdapter,
          editorTranslation,
          {
            manageStaffAction: actions.manageStaffAction,
            staffListSettingsAction: actions.openStaffListSettings,
            openStaffListTextSettingsAction: actions.openStaffListTextSettings,
          },
        ),
      };
      await editorSdkAdapter.registerToCustomEvents([componentStyleChanged]);

      const isEcomSiteAndHistoryModalEnabled = await isExperimentEnabled(
        experiments.ECOM_SITE_AND_HISTORY_MODAL_ENABLED,
      );
      const isEcomMigrationEnabled = await isExperimentEnabled(
        experiments.IS_ECOM_MIGRATION_ENABLED,
      );
      return new Promise(async (resolve) => {
        appToken = _appToken;
        sdk = _editorSDK;
        isAdi = options.origin && options.origin.type === 'ADI';
        if (await isBookingsCheckoutInstalled(sdk)) {
          await addBookingsPagesAsPanel(sdk, appToken);
        }
        // if (!isAdi && await isNoBookingsPagesInstalled(sdk)) {
        //   await handleBrokenNoPagesMigration(sdk, appToken);
        //   return Promise.resolve();
        // }
        if (isFirstInstall) {
          const setupBookingsBoilerplate = async () => {
            await addBookingsPagesAsPanel(sdk, appToken);
            const isIntroFunnelInstallation =
              options.origin?.info?.type ===
              InstallationOriginType.INTRO_FUNNEL;
            const isSilentInstall =
              isSkipMemberInstallationEnabled &&
              (options.origin?.info?.type ===
                InstallationOriginType.SILENT_INSTALL_SITE_CREATION ||
                options.origin?.info?.type ===
                  InstallationOriginType.SILENT_INSTALL);
            if (!isAdi && !isIntroFunnelInstallation && !isSilentInstall) {
              await maybeInstallMembersArea({ biData: options.biData });
            }
          };
          try {
            await sdk.document.transactions.runAndWaitForApproval(
              appToken,
              async () => {
                await setupBookingsBoilerplate();
              },
            );
          } catch (error) {
            console.error('Concurrent editing problem: ', error);
          }
        } else {
          await sdk.document.application.registerToCustomEvents(appToken, {
            eventTypes: [pageChangedEvent],
          });
          isProposeMigrationNeeded = shouldProposeMigration(
            sdk,
            isAdi,
            appToken,
            instance,
          );
        }
        if (isOOITemplateMigrationEnabled) {
          if (
            isBookingsOnEcom &&
            (await skipEcomInstallation()) &&
            !(await editorScriptApi.getIsBookingsOnEcom())
          ) {
            await addBookingCheckoutPage(sdk, appToken);
          }
        } else {
          if (!isBookingsOnEcom || !(await editorScriptApi.canInstallEcom())) {
            await addBookingCheckoutPage(sdk, appToken);
          }
        }

        allSitePages = await sdk.pages.data.getAll();
        await createBookCheckoutState(sdk, appToken, allSitePages);
        await createServicePageState(sdk, appToken, allSitePages);
        await createBookingCalendarState(sdk, appToken, allSitePages);
        await createBookingFormState(sdk, appToken, allSitePages);
        if (!isEcomMigrationEnabled) {
          await removeBookCheckoutPageOnEcom(sdk, appToken, editorScriptApi);
        }

        if (!isFirstInstall && isEcomSiteAndHistoryModalEnabled) {
          const isEcomRolloutButNotSite = await ecomRolloutButNotSite(
            _editorSDK,
            editorScriptApi,
          );

          if (isBookingsOnEcom && isEcomRolloutButNotSite) {
            await openEcomHistoryBlockModal(
              _editorSDK,
              editorScriptApi,
              locale,
              instance,
            );
          }
        }

        editorReadyDone = true;
        resolve(undefined);
      });
    },
    getAppManifest: () => {
      return {
        ...(appDescriptor ? { appDescriptor } : {}),
        controllersStageData: {
          [DAILY_TIMETABLE_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[
                DAILY_TIMETABLE_WIDGET_DEV_CENTER_ID
              ].connection,
          },
          [BOOK_BUTTON_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[BOOK_BUTTON_WIDGET_CONTROLLER_ID]
                .connection,
          },
          [STAFF_LIST_WIDGET_CONTROLLER_ID]: {
            default:
              bookingsEditorComponentModels[STAFF_LIST_WIDGET_DEV_CENTER_ID]
                .connection,
          },
          ...ooiControllersStageData,
        },
        pages: {
          pageActions: {
            default: [
              'Pages_Actions_Page_Rename',
              {
                title: editorTranslation.t('bookings-pages.page.delete'),
                event: deletePageEvent,
                icon: 'deleteAction',
                type: 'page_remove',
              },
            ],
            bookCheckoutPage: [],
            servicePage: [],
            bookingCalendar: [],
            bookingForm: [],
          },
          pageSettings: {
            default: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.seo'),
                type: 'seo',
              },
            ],
            bookCheckoutPage: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/book-checkout-page-info?locale=${locale}&instance=${instance}&isBookingCalendarInstalled=${isBookingCalendarPageInstalled}`,
                helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            servicePage: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/service-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            bookingCalendar: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/booking-calendar-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
            bookingForm: [
              {
                title: editorTranslation.t('bookings-pages.tabs.page-info'),
                url: `https://bookings.wixapps.net/bookings-widget/booking-form-page-info?locale=${locale}&instance=${instance}`,
                helpId: '7137bae8-3fe7-41ab-a7f5-80eddb9d9bad',
                type: 'page_info',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.layout'),
                type: 'layout',
              },
              {
                title: editorTranslation.t('bookings-pages.tabs.permissions'),
                type: 'permissions',
              },
            ],
          },
          applicationSettings: {
            default: {
              displayName: editorTranslation.t('bookings-pages.title'),
              helpId: 'c7cfedc0-f0c7-4ea3-9f91-9e1e9a5f7b33',
            },
          },
          applicationActions: {
            default: {
              defaultValues: [
                {
                  title: editorTranslation.t('bookings-pages.actions.manage'),
                  event: manageBookingsEvent,
                  icon: 'settingsAction',
                },
                {
                  title: editorTranslation.t('bookings-pages.actions.delete'),
                  event: deleteBookingsEvent,
                  icon: 'deleteRadio',
                },
              ],
            },
          },
          pageDescriptors: {
            default: {
              icon: 'bookingPageType',
              orderIndex: 5,
            },
            servicePage: {
              icon: 'bookingPageType',
              orderIndex: 4,
            },
            bookingCalendar: {
              icon: 'bookingPageType',
              orderIndex: 3,
            },
            bookingForm: {
              icon: 'bookingPageType',
              orderIndex: 2,
            },
            bookCheckoutPage: {
              icon: 'bookingPageType',
              orderIndex: 1,
            },
          },
        },
      };
    },
    onEvent: async (event, editorSDK) => {
      const { eventType, eventPayload } = event;
      const editorSdkAdapter = new EditorSdkAdapter(editorSDK, appToken);
      onHistoryEvent(event);

      async function openManageStaff() {
        await editorSdkAdapter.openStaffList('editor');
        editorSdkAdapter.refreshApp('MANAGE_BOOKINGS_CLOSE');
      }

      function focusOnStateBox(widgetComponentRef) {
        getStateBoxByBookingsAppBuilderWidget(
          editorSDK,
          appToken,
          widgetComponentRef,
        ).then((stateBoxComponentRef) => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }

      function getWidgetComponentRef(componentRef) {
        getBookingsAppBuilderWidgetByChildComponentRef(
          editorSDK,
          appToken,
          componentRef,
        ).then((stateBoxComponentRef) => {
          editorSdkAdapter.selectComponent(stateBoxComponentRef);
        });
      }

      async function openDailyTimeTableSettingsPanel(
        componentRef,
        tab?: 'text',
      ) {
        const innerPath = tab ? `/${tab}` : ``;

        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/daily-timetable/settings${innerPath}`;
        const title = editorTranslation.t(
          'bookings.daily-timetable.settings.label.Header',
        );
        const helpId = 'b78664ce-6970-4eb8-9ee6-6ce1d79d9b61';
        const options = {
          width: 406,
        };
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
          options,
        );
      }

      async function openStaffListSettingsPanel(componentRef, tab = '') {
        const url = `https://bookings.wixapps.net/bookings-app-builder-statics/staff-widget/settings/${tab}`;
        const title = editorTranslation.t(
          'bookings.staff-list.settings.label.Header',
        );
        const helpId = '8a5134e7-7689-4772-acc4-fb2a9830b54e';
        const options = {
          width: 406,
        };
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
          options,
        );
      }

      async function openBookButtonSettingsPanel(componentRef) {
        const url = `${BookingsAppBuilderStaticsUrls.BOOK_BUTTON_SETTINGS}`;
        const title = editorTranslation.t(
          'bookings.book-button.settings.label.Header',
        );
        const helpId = '0b25d4f6-6381-4d40-ac59-97a7d14cc7c2';
        await editorSdkAdapter.openSettingsPanel(
          title,
          url,
          componentRef,
          helpId,
          instance,
        );
      }

      async function handleComponentStyleChange() {
        const styledComponentRef = eventPayload.compRef;
        const linkedBookingsWidget = await getParentWidget(styledComponentRef);
        if (linkedBookingsWidget) {
          const { widgetComponentRef, editorComponentModel } =
            linkedBookingsWidget;
          await editorComponentModel.handleStylesChange(
            widgetComponentRef,
            styledComponentRef,
          );
        }
      }

      async function getParentWidget(componentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {
          componentRef,
        });
        for (const ancestor of ancestors) {
          const ancestorId = await editorSdkAdapter.getWidgetId(ancestor);
          if (bookingsEditorComponentModels[ancestorId]) {
            return {
              widgetComponentRef: ancestor,
              editorComponentModel: bookingsEditorComponentModels[ancestorId],
            };
          }
        }
        return null;
      }

      async function getParentWidgetCompRef(childComponentRef) {
        const ancestors = await editorSDK.components.getAncestors(appToken, {
          componentRef: childComponentRef,
        });
        for (const ancestor of ancestors) {
          const data = await editorSDK.components.data.get(appToken, {
            componentRef: ancestor,
          });
          if (data?.type === 'AppController') {
            return ancestor;
          }
        }
        return null;
      }

      async function handleDoubleClick() {
        let parentWidget;
        try {
          parentWidget = await getParentWidget(eventPayload.componentRef);
        } catch (e) {
          // todo try for book-button
        }
        if (
          parentWidget?.editorComponentModel.baseComponentModel
            .doubleClickAction
        ) {
          eventPayload.id =
            parentWidget.editorComponentModel.baseComponentModel.doubleClickAction.actionId;
        }
      }

      switch (eventType) {
        case appActionClicked:
          if (eventPayload.actionId === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          } else if (eventPayload.actionId === openBookingsAddPanel) {
            void handleOpenBookingsAddPanel(editorSDK, appToken);
          } else if (eventPayload.actionId === openBookingsPagesPanel) {
            void handleOpenBookingsPagesPanel(editorSDK, appToken);
          } else if (eventPayload.actionId === createAServiceEvent) {
            void openBookingsDashboardAddNewService(
              editorSdkAdapter,
              editorSDK,
              appToken,
            );
          }
          break;
        case componentStyleChanged:
          void handleComponentStyleChange();
          break;
        case widgetGfppClicked:
          if (eventPayload.gfppOrigin === gfppOriginDoubleClick) {
            await handleDoubleClick();
          }
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(eventPayload.componentRef);
          }
          if (eventPayload.id === openBookButtonSettingsEvent) {
            void openBookButtonSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(eventPayload.componentRef);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          break;
        case componentGfppClicked:
          const parentWidget = await getParentWidgetCompRef(
            eventPayload.componentRef,
          );
          if (eventPayload.id === manageBookingsEvent) {
            void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          }
          if (eventPayload.id === openStaffListSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget);
          }
          if (eventPayload.id === manageStaffEvent) {
            void openManageStaff();
          }
          if (eventPayload.id === openDailyTimeTableSettingsTextTabEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget, 'text');
          }
          if (eventPayload.id === openDailyTimeTableSettingsEvent) {
            void openDailyTimeTableSettingsPanel(parentWidget);
          }
          if (eventPayload.id === openStaffListTextSettingsEvent) {
            void openStaffListSettingsPanel(parentWidget, 'text');
          }
          if (eventPayload.id === manageStateEvent) {
            focusOnStateBox(parentWidget);
          }
          break;
        case manageBookingsEvent:
          void openManageBookings(editorSdkAdapter, editorSDK, appToken);
          break;
        case manageStateEvent:
          focusOnStateBox(getWidgetComponentRef(eventPayload.componentRef));
          break;
        case deleteBookingsEvent:
          const bookingsPages = await getAllBookingsPages(editorSDK, appToken);
          const essentialPage = bookingsPages.find(
            (page) => page.tpaPageId === PageId.BOOKINGS_LIST,
          );
          await removePage(editorSDK, appToken, essentialPage.id);
          break;
        case deletePageEvent:
          await removePage(editorSDK, appToken, eventPayload.pageRef.id);
          break;
        case pageChangedEvent:
          const pageData = await getPageData(
            sdk,
            appToken,
            eventPayload.pageRef,
          );
          if (
            pageData.tpaPageId === PageId.SCHEDULER &&
            isProposeMigrationNeeded &&
            !(await isBookingsCheckoutInstalled(editorSDK))
          ) {
            await proposeListMigration(
              sdk,
              appToken,
              locale,
              instance,
              editorTranslation,
              allSitePages,
            );
          } else {
            if (
              pageData.tpaPageId === PageId.BOOKINGS_LIST &&
              !isAdi &&
              (!(await isServicePageInstalled(sdk)) ||
                !(await isBookingCalendarInstalled(sdk)) ||
                !(await isBookingFormInstalled(sdk)))
            ) {
              await migrator({
                sdk,
                appToken,
                locale,
                instance,
                editorTranslation,
                allSitePages,
                editorScriptApi,
                referral: pageData.tpaPageId,
              });
            }
          }
          break;
        default:
      }
    },
    handleAction: async (
      { type, payload }: { type: string; payload: HandleActionPayload },
      editorSdk,
    ) => {
      const editorSdkAdapter = new EditorSdkAdapter(editorSdk, appToken);
      const isJustToInstallPricingPlans = payload?.withPricingPlans;
      const isOOIMigration = payload?.OOIMigration;
      const isOOICheckCanMigrate = payload?.OOICheckCanMigrate;

      try {
        switch (type) {
          case 'appInstalled':
            try {
              const isEcomMigrationEnabled = await isExperimentEnabled(
                experiments.IS_ECOM_MIGRATION_ENABLED,
              );

              if (payload.appDefinitionId === ECOM_APP_DEF_ID) {
                if (
                  isBookingsOnEcom &&
                  isFirstInstall &&
                  !isEcomMigrationEnabled
                ) {
                  isEcomReady = await markAsEcom({
                    isEcomReady,
                    editorSdk,
                    editorScriptApi,
                    appToken,
                  });
                }
              }
              if (payload.appDefinitionId === BOOKINGS_APP_DEF_ID) {
                if (
                  isBookingsOnEcom &&
                  isFirstInstall &&
                  !isEcomMigrationEnabled &&
                  (await editorSdkAdapter.isEcomInstalled())
                ) {
                  isEcomReady = await markAsEcom({
                    isEcomReady,
                    editorSdk,
                    editorScriptApi,
                    appToken,
                  });
                }
              }
            } catch (e) {
              const errorMessage = `${
                e?.message ? e.message : JSON.stringify(e)
              } - errorCode: APP_INSTALLED_ACTION`;
              throw new Error(errorMessage);
            }
            break;
          case 'migrate':
            try {
              if (!(await isBookingInstalled(editorSdk))) {
                break;
              }

              await editorScriptApi.sleepUntil(() => editorReadyDone);

              if (isJustToInstallPricingPlans) {
                await sdk.document.tpa.add.application(appToken, {
                  appDefinitionId: PRICING_PLANS_DEF_ID,
                });
              }
              if (isBookingsOnEcom) {
                if (isOOIMigration) {
                  if (
                    !(await editorScriptApi.shouldMigrateEcom(
                      isOOICheckCanMigrate,
                    ))
                  ) {
                    throw new Error(
                      `should not migrate - errorCode: ec083f47-92ef-44f1-a073-714dc3a1fc67`,
                    );
                  }

                  await migrateToOOI({
                    editorSDK: editorSdk,
                    appToken,
                    editorScriptApi,
                    instance,
                    handleActionPayload: payload,
                  });
                }
              }
            } catch (e) {
              const errorMessage = `${
                e?.message ? e.message : JSON.stringify(e)
              } - errorCode: MIGRATE_ACTION`;
              throw new Error(errorMessage);
            }
            break;
          case 'removeApp':
            try {
              await onRemoveApp(sdk, appToken);
            } catch (e) {
              const errorMessage = `${
                e?.message ? e.message : JSON.stringify(e)
              } - errorCode: REMOVE_APP_ACTION`;
              throw new Error(errorMessage);
            }
            break;
          default:
            return Promise.resolve();
        }
      } catch (e) {
        const errorMessage = `${
          e?.message ? e.message : JSON.stringify(e)
        } | Handle Action Failed - errorCode: 8ad89f0b-96c9-4538-9158-bc941831812e`;
        Promise.reject(errorMessage);
        throw new Error(errorMessage);
      }
    },
    getControllerPresets: async () => {
      return [];
    },
  };
};

export const createBookingsEditorScriptWithMembers = (
  isBookingsOnEcom = false,
) => {
  try {
    return withMembersArea(createEditorScript(isBookingsOnEcom), {
      installAutomatically: false,
      membersAreaApps: [MA_APP_IDS.MY_BOOKINGS, MA_APP_IDS.MY_WALLET],
    });
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: WITH_MEMBER_AREA_EDITOR_SCRIPT_ERROR`;
    throw new Error(errorMessage);
  }
};

export const createBookingsEditorScriptWithEcom = () => {
  try {
    return withEcomPlatform(
      createBookingsEditorScriptWithMembers(true) as any,
      () => requiredEcomComponents(),
      {
        skipInstallation: () => skipEcomInstallation(),
      },
    );
  } catch (e) {
    const errorMessage = `${
      e?.message ? e.message : JSON.stringify(e)
    } - errorCode: WITH_ECOM_PLATFORM_EDITOR_SCRIPT_ERROR`;
    throw new Error(errorMessage);
  }
};
