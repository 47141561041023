import { PageId, HandleActionPayload } from '../../constants';
import { getAllSiteComponents } from '../editor-sdk-actions';

export abstract class BasePageMigrator {
  public constructor(
    protected editorSDK,
    protected appToken,
    protected instance,
    protected handleActionPayload: HandleActionPayload,
  ) {}

  public abstract shouldMigrate();

  public abstract execute();

  protected abstract get widgetId();

  public abstract get widgetName();

  protected async addBookingsPage(pageId: PageId) {
    try {
      return await this.editorSDK.document.tpa.add.component(this.appToken, {
        componentType: this.editorSDK.document.tpa.TPAComponentType.Page,
        page: {
          pageId,
          shouldNavigate: false,
        },
      });
    }  catch (e) {
      const errorMessage = `${
        e?.message ? e.message : JSON.stringify(e)
      } | addBookingsPage Failed. pageId: ${pageId}`;
      throw new Error(errorMessage);
    }
  }

  protected async updateComponentStyles(compId, style) {
    try {
      return await this.editorSDK.document.components.style.update(this.appToken, {
        componentRef: { type: 'DESKTOP', id: compId },
        style,
      });
    } catch (e) {
      const errorMessage = `updateComponentStyles Failed - ${(e?.message ? e.message : JSON.stringify(e))}`;
      throw new Error(errorMessage);
    }
  }

  protected async updateComponentSettings(compId, config) {
    try {
      return await this.editorSDK.document.controllers.saveConfiguration(
        this.appToken,
        {
          controllerRef: { type: 'DESKTOP', id: compId },
          config,
        },
      );
    } catch (e) {
      const errorMessage = `updateComponentSettings Failed - ${(e?.message ? e.message : JSON.stringify(e))}`;
      throw new Error(errorMessage);
    }
  }

  protected async getAllSitePages() {
    try {
      return await this.editorSDK.pages.data.getAll();
    } catch (e) {
      const errorMessage = `getAllSitePages Failed - ${(e?.message ? e.message : JSON.stringify(e))}`;
      throw new Error(errorMessage);
    }
  }

  protected async getSiteStructure() {
    const allComponents = await getAllSiteComponents(this.editorSDK, this.appToken);
    return Promise.all(
      allComponents.map((componentRef) =>
        this.getFullComponentStructure(componentRef),
      ),
    );
  }

  protected async createPageState(pageId: PageId, pageName: string) {
    try {
      const allSitePages = await this.getAllSitePages();
      const pageData = allSitePages.find((page) => page.tpaPageId === pageId);
      if (pageData) {
        await this.editorSDK.document.pages.setState(this.appToken, {
          state: {
            [pageName]: [{ id: pageData.id }],
          },
        });
      }
    } catch (e) {
      const errorMessage = `createPageState Failed - ${(e?.message ? e.message : JSON.stringify(e))}`;
      throw new Error(errorMessage);
    }
  }

  protected get bookingCheckoutWidgetId() {
    return '713f72a2-7a32-47e9-b5e4-6024ee57d277';
  }

  protected findWidgetStructure(siteStructure, widgetId) {
    return siteStructure.find(
      (comp) =>
        comp.data?.widgetId === widgetId,
    );
  }

  private async getFullComponentStructure(componentRef) {
    try {
      const result = await this.editorSDK.document.components.serialize(this.appToken, {
        componentRef,
        maintainIdentifiers: true,
      });

      return {
        id: componentRef.id,
        ...result
      }
    } catch (e) {
      const errorMessage = `${(e?.message ? e.message : JSON.stringify(e))} - errorCode: GET_FULL_COMPONENTS_STRUCTURE_FAILED with componentRef: ${JSON.stringify(componentRef)}`;
      throw new Error(errorMessage);
    }
  }
}
